.date-selector__textfield {
  width: 250px;
}

.add-date__textfield__type,
.add-date__textfield__name {
  margin-top: 8px;
  margin-bottom: 8px;
}

.admin-dialog__paper {
  min-height: 40rem;
}